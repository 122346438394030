// Override default variables before the import
// $primary: rgb(47, 128, 237);
// $secondary: black;
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&family=Open+Sans:wght@400;700&display=swap");

$theme-colors: (
  "primary": #2f80ed,
  "secondary": #000,
  "success": #03bc51,
  "danger": #eb5757,
  "light": #f2f2f2,
);

$font-family-sans-serif: "Open Sans", sans-serif;
$headings-font-family: "Kumbh Sans", sans-serif;
$headings-font-weight: 200;
$display3-weight: 700;
$btn-border-radius: 8px;

//Body
$body-bg: #fff;
$body-color: #000;

//Card
$card-bg: #fff;
$card-border-width: 0;

* {
  :focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.pointer {
  cursor: pointer;
}

.rounded-14 {
  border-radius: 12px !important;
}

.search-bar {
  width: 80%;
}
.card {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px !important;
}

.card-header {
  background-color: #fff !important;
}

.shadow-light {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
}

button.rounded {
  border-radius: 12px !important;
}

.list-group-item {
  border-radius: 12px !important;
}
.empty-state {
  background: #f2f2f233 !important;
  border: 1px solid #eee !important;
  color: rgb(110, 110, 110);
  border-radius: 6px !important;
}

html {
  max-width: 100%;
  overflow-x: hidden;
}

.fill-height {
  min-height: 100vh;
  height: 100%;
  z-index: 12;
}

.cover {
  object-fit: cover;
}

.carousel-indicators .active {
  background-color: #2f80ed !important;
  border-color: #2f80ed !important;
}
.carousel-indicators li {
  background-color: #000 !important;
  height: 8px !important;
  width: 8px !important;
  border-radius: 50% !important;
  border: 2px solid #000 !important;
}
.carousel-control-prev,
.carousel-control-next {
  color: #000 !important;
}

.carousel-control-prev {
  height: 100px;
}

.carousel-control-next {
  height: 100px;
}

.carousel-indicators {
  bottom: -50px !important;
}

.carousel-inner {
  padding: 20px;
}
//Body
.top-offset {
  margin-top: 54px;
  min-height: calc(100vh - 340px);
}

.top-offset.terms {
  p {
    margin-top: 40px;
  }
  .font-weight-bold {
    margin-bottom: 0px;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

//Progress bar
.progress {
  height: 0.5rem !important;
  border-radius: 0.5rem !important;
}

.bg-success {
  background: linear-gradient(90deg, #6fcf97 0.18%, #6bf1a3 89.17%, #41e07e 100.18%), #3ece7a;
}

.bg-danger {
  background: linear-gradient(90deg, #f57a9f 1.04%, #f57a9f 48.04%, #eb5757 101.04%),
    linear-gradient(270deg, #fa6c6c -1.04%, #d96b8a 88.54%);
}

//Backgrounds
.login-background {
  background: linear-gradient(
    164.43deg,
    rgba(86, 204, 242, 0.75) 8.7%,
    rgba(86, 204, 242, 0.75) 26.61%,
    rgba(111, 207, 151, 0.75) 94.67%
  );
  background-blend-mode: overlay, normal;
  display: flex;
  min-height: 100vh;
  align-items: center;
}

.onboarding-background {
  background: linear-gradient(
    rgba(86, 204, 242, 0.75) 8.7%,
    rgba(86, 204, 242, 0.75) 26.61%,
    rgba(111, 207, 151, 0.75) 94.67%
  );
  display: flex;
  min-height: calc(100vh - 54px);
  align-items: center;
}

.onboarding-card {
  min-height: 400px;
}
//Buttons

.max-button {
  max-width: 400px;
  margin: 0 auto;
}

.btn.btn-notsubscribed {
  background-color: white;
  border: 1px solid #bdbdbd;
  text-align: left;
  border-radius: 0.4rem;
}

.btn.btn-connected {
  background-color: f2f2f2;
  border: 1px solid #f2f2f2;
  text-align: left;
  border-radius: 0.4rem;
}
.btn:focus,
.btn.focus {
  box-shadow: none !important;
}
button:focus {
  box-shadow: none !important;
  outline: none !important;
}

//Modal
$modal-content-bg: #f2f2f2;
$modal-header-border-width: 0;
$modal-dialog-margin: 0;

$list-group-border-width: 0;
$list-group-bg: #fff;

.navbar {
  min-height: 56px;
  border-bottom: solid 1px #e0e0e0;
}

.no-border {
  border: none;
}

.semi-opaque {
  opacity: 0.7;
}
//Link
$link-color: #2f80ed;
$link-decoration: none;
$link-hover-color: black;
$link-hover-decoration: none;

//Popover
.popover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  max-width: 310px !important;
  z-index: 1008 !important; 
}

.type-selected button {
  border-color: #2f80ed !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  color: #000;
}

.type-selected button:focus {
  box-shadow: 0px 0px 4px rgba(40, 115, 255, 0.25) !important;
}

//Progress Bar
.smallProgressBar.progress {
  height: 10px;
  border-radius: 40px;
  background-color: #e0e0e0;

  .progress-bar {
    border-radius: 40px;
  }
}

.box {
  border: 1px solid #e0e0e0;
  padding: 12px;
  border-radius: 12px;
}

.divider-basic {
  height: 4px;
  border-radius: 14px;
  margin: 20px 0;
  background: linear-gradient(90deg, #56ccf2 0%, #9b51e0 100%);
}

.divider-premium {
  height: 4px;
  border-radius: 14px;
  margin: 20px 0;
  background: linear-gradient(90deg, #9b51e0 0%, #eb5757 100%);
}

.divider-free {
  height: 4px;
  border-radius: 14px;
  margin: 20px 0;
  background: linear-gradient(90deg, #27ae60 0%, #56ccf2 100%);
}
//Alert
.alert-info {
  background-color: #eef7f9 !important;
  border: 0 !important;
}

//Toast

/** Classes for the displayed toast **/
.Toastify__toast {
  color: black;
  background: white;
  font-weight: 700;
  font-family: "Kumbh Sans", sans-serif;
}
.Toastify__toast--success {
  .Toastify__progress-bar {
    background-color: #03bc51;
  }
}
.Toastify__toast--error {
  .Toastify__progress-bar {
    background-color: #eb5757;
  }
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  color: black;
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
  background-color: black;
}

//Hero
.hero {
  background-image: url(./images/hero-image.jpg);
  background-color: white;
  background-position: bottom;
  background-size: cover;
  min-height: 70vh;
}

.hero-rentals {
  background-image: url(./images/rental-image.jpg);
  background-color: white;
  background-position: bottom;
  background-size: cover;
  min-height: 70vh;
}

.overview {
  background-color: #f2f2f2 !important;
  background-size: cover;
}

.cta {
  background-image: url(./images/cta-background.jpg);
  background-size: contain;
  background-position: top center;
  background-color: transparent !important;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 260px;
}

.purchase {
  background-image: url(./images/purchase-background.jpg);
  background-size: contain;
  background-position: top center;
  background-color: transparent !important;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 280px;
}

.callout1-dash {
  position: static;
  width: 120px;
  height: 10px;
  background: linear-gradient(90deg, #27ae60 0%, #56ccf2 100%);
  border-radius: 24px;
  margin-left: 0;
}

.callout2-dash {
  position: static;
  width: 120px;
  height: 10px;
  background: linear-gradient(90deg, #9b51e0 0%, #eb5757 100%);
  border-radius: 24px;
  margin-left: 0;
}

.callout3-dash {
  position: static;
  width: 120px;
  height: 10px;
  background: linear-gradient(90deg, #9b51e0 0%, #56ccf2 100%);
  border-radius: 24px;
  margin-left: 0;
}

.callout4-dash {
  position: static;
  width: 120px;
  height: 10px;
  background: linear-gradient(90deg, #f2c94c 0%, #f1907f 100%);
  border-radius: 24px;
  margin-left: 0;
}

.callout5-dash {
  position: static;
  width: 120px;
  height: 10px;
  background: linear-gradient(90deg, #6fcf97 0%, #56ccf2 100%);
  border-radius: 24px;
}

.footer-logo {
  max-height: 80px;
  width: auto;
}

.fixed-footer {
  position: absolute;
  min-width: 100vw;
}

.page-wrapper {
  min-height: calc(100vh - 132px);
  padding-top: 60px;
}

.logo-align {
  margin-left: 60px !important;
}

.cover-image {
  border-radius: 16px 16px 0 0 !important;
}

.row-image {
  border-radius: 12px 0 0 12px;
}

.image-container {
  height: 240px;
  background: #eee;
  border-radius: 12px;
  overflow: hidden;
}

.asset-image-container {
  height: 180px;
  background: #eee;
  border-radius: 12px;
  overflow: hidden;
}

.recharts-layer {
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25), 1px 0 0 rgba(0, 0, 0, 0.75);
}

.hide {
  opacity: 0;
}

.fade-in {
  transition: opacity 1s;
  -webkit-transition: opacity 1s;
  opacity: 1;
}

.adjust-height {
  min-height: 150px;
}

.z-index-override {
  z-index: 9999 !important;
}

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices
//(landscape phones, 576px and up)
@media (max-width: 578px) {
  .display-2 {
    font-size: 2.5rem !important;
    line-height: 1 !important;
  }
  h2,
  .h2 {
    font-size: 1.25rem !important;
    line-height: 1 !important;
  }
  h3,
  .h3 {
    font-size: 1.1rem !important;
    line-height: 1 !important;
  }
  .hero {
    background-position: center !important;
    background-size: cover !important;
    min-height: 65vh !important;
  }
}

//Hamburger Drawer

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 20px;
  left: 24px;
  top: 22px;
  z-index: 1050 !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #333333;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #2f80ed;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 40px !important;
  width: 40px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #2d3033;
  padding: 2.5em 1.5em 0;
  line-height: 2.4;
  a:hover {
    color: #ffffff !important;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  :hover {
    color: #ffffff !important;
  }
}

.text-soft {
  color: #a6a6a6 !important;
}

.text-soft:hover {
  color: #a6a6a6 !important;
}

.line-height-1 {
  line-height: 1 !important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.1) !important;
}

.sq-icon {
  width: 100px;
}

.sticky {
  position: sticky !important;
  top: 80px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #333 !important;
  border-radius: 24px;
}

.nav-pills > a {
  font-size: 0.9em !important;
  line-height: 1 !important;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .display-2 {
    font-size: 2.5rem !important;
  }
  h2,
  .h2 {
    font-size: 1.46rem !important;
  }
  h3,
  .h3 {
    font-size: 1.33rem !important;
    line-height: 1 !important;
  }
  h4,
  .h4 {
    font-size: 1.15rem !important;
    line-height: 1 !important;
  }
  h5,
  .h5 {
    font-size: 1.1rem !important;
  }

  .hero {
    background-position: top;
    background-size: cover;
    min-height: 60vh;
  }
}

//Typography

h1.display-2 {
  line-height: 1;
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .hero {
    background-position: center;
    background-size: cover;
    min-height: 60vh;
  }
  .display-2 {
    font-size: 2.5rem;
  }
}

//Forms
.onboarding-step {
  div.form-check {
    margin: 20px 0;
    padding-left: 10px;
  }
  input[type="radio"] {
    width: 20px;
    height: 20px;
  }
  .form-check-input {
    margin-left: -1.75rem !important;
  }
  .form-check-label {
    font-size: 18px;
  }
  min-height: 200px;
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .hero {
    background-position: center;
    background-size: cover;
    min-height: 60vh;
  }
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
